import React from 'react';
import { Spin } from 'antd';
import { ErrorPage } from '@page/error/errorPage';
import './index.less';

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iserror: false, // 是否错误
            isloading: false, // 是否加载中
        }
        // 组件是否挂载了
        this.mounted = false;
    }
    // 从服务器请求数据
    requestFromServer() {

    }
    // 组件卸载回调
    componentWillUnmount() {
        // 标记组件未挂载
        this.mounted = false;
    }
    // 组件加载回调
    componentDidMount() {
        // 标记组件挂载了
        this.mounted = true;
        // 从服务器请求数据
        this.requestFromServer();
    }
    render() {
        const isError = this.state.iserror;
        // 错误提示
        let alert = null;
        // 判断是否加载错误
        if (isError) {
            // 错误提示页
            alert = <ErrorPage title={'获取数据失败'} desc={'请稍后重试...'} btnFont={'重试'} onBtnClick={() => this.requestFromServer()} />
        }
        return (
            <Spin spinning={this.state.isloading}>
                {alert}
                <div className='site-children-page'>
                    UI布局页面
                </div>
            </Spin>
        )
    }
}

export default IndexPage;