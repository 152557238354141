import React from 'react';
import { Typography } from 'antd';
import './index.less';
import mainpc1 from './../../../assets/mainpic1.png'
import mainpc2 from './../../../assets/mainpic2.png'
import mainpc3 from './../../../assets/mainpic3.png'
import mainpc4 from './../../../assets/mainpic4.png'
import mainpc5 from './../../../assets/mainpic5.png'


class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iserror: false, // 是否错误
            isloading: false, // 是否加载中
        }
        // 组件是否挂载了
        this.mounted = false;
    }
    // 从服务器请求数据
    requestFromServer() {

    }
    // 组件卸载回调
    componentWillUnmount() {
        // 标记组件未挂载
        this.mounted = false;
    }
    // 组件加载回调
    componentDidMount() {
        // 标记组件挂载了
        this.mounted = true;
        // 从服务器请求数据
        this.requestFromServer();
    }
    render() {

        return (
            <div className='site-main-page'>
                <div className='rowLyr'>
                    <div className='item'>
                        <div className='title'>1.您如何做到保护用户隐私的同时，精准衡量营销活动的效果</div>
                        <div className='desc'>获取新用户。保护终端用户隐私，获取精准洞察。我们隐私至上的归因衡量方案与成本聚合方案不受平台、渠道与设备的局限，获取全面精准的营销洞察。</div>

                    </div>
                    <div className='item'>
                        <img className='img' src={mainpc1} />
                    </div>
                </div>
                <div className='rowLyr'>
                    <div className='item'>
                        <div className='title'>2.您是否掌握营销活动的真实效果？</div>
                        <div className='desc'>营销预算的每一分钱都要花在刀刃上。增量分析为您提供精准、公正的营销洞察，助您了解营销活动的真实增效，做出更明智的营销决策。</div>

                    </div>
                    <div className='item'>
                        <img className='img' src={mainpc2} />
                    </div>
                </div>
                <div className='rowLyr'>
                    <div className='item'>
                        <div className='title'>3.如何真正做到将数据洞察用于指导实践？</div>
                        <div className='desc'>广告投放不可能是千篇一律的。我们的营销分析工具组合将复杂数据简单化，为您提供广告活动表现的全貌，帮助您的用户旅程的每一节点快速做出更明智的营销决策。</div>

                    </div>
                    <div className='item'>
                        <img className='img' src={mainpc3} />
                    </div>
                </div>
                <div className='rowLyr'>
                    <div className='item'>
                        <div className='title'>4.您是否能够创建卓越的用户体验，提升用户参与度？</div>
                        <div className='desc'>您需要了解用户群体构成以及用户属性。体验管理与互动工具组合基于强大的深度链接技术，帮助您创建高度定制化的用户旅程，提升转化与体验回报率。</div>

                    </div>
                    <div className='item'>
                        <img className='img' src={mainpc4} />
                    </div>
                </div>
                <div className='rowLyr'>
                    <div className='item'>
                        <div className='title'>5.如何防止流量被假量污染，保护营销预算？</div>
                        <div className='desc'>机器人作弊与设备农场不会给您带来任何收入。因此您就需要全面的防作弊解决方案，选择正确的媒体渠道，只衡量真实用户行为，只为真实转化买单。</div>

                    </div>
                    <div className='item'>
                        <img className='img' src={mainpc5} />
                    </div>
                </div>
            </div>
        )
    }
}

export default IndexPage;