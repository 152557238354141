import React from 'react';
import './index.less';

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        // 组件是否挂载了
        this.mounted = false;
    }
    // 组件卸载回调
    componentWillUnmount() {
        // 标记组件未挂载
        this.mounted = false;
    }
    // 组件加载回调
    componentDidMount() {
        // 标记组件挂载了
        this.mounted = true;
    }
    render() {
        return (
            <div className='site-ours-page'>
                <p>广州亿趣网络科技有限公司</p>
                <p>“亿趣网络”于2018年初成立，注册资本100万，专注于互联网相关技术的研发以及运营。</p>
                <p>公司拥有一流创意团队、技术班底和运营能力；团队核心成员从业7年以上，在发行和运营方面具备相当成熟的经验。</p>
                <p>联系电话：15626100247</p>
            </div>
        )
    }
}

export default IndexPage;