import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
} from "react-router-dom";
import React from "react";
import Home from '@page/home/home';
import { Main } from '@component';
import { ProvideAuth, PrivateRoute } from '@component/auth/provideAuth';
import './App.less';

function App() {
    return (
        <ProvideAuth>
            <Router basename="">
                <Switch>
                    <Main>
                        <Route path="/home"><Home /></Route>
                        <PrivateRoute >
                            <Redirect to='/home' />
                        </PrivateRoute>
                    </Main>
                </Switch>
            </Router>
        </ProvideAuth>
    )
}
export default App;


