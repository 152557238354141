
import { useContext, createContext, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { getToken } from '@util/GameUtil';

const fakeAuth = {
    isAuthenticated: false,
    signin(cb) {
        fakeAuth.isAuthenticated = true;
        setTimeout(cb, 100); // fake async
    },
    signout(cb) {
        fakeAuth.isAuthenticated = false;
        setTimeout(cb, 100);
    }
};
function useProvideAuth() {
    const [user, setUser] = useState(null);

    const signin = (token, cb) => {
        return fakeAuth.signin(() => {
            setUser(token);
            if (cb) {
                cb();
            }
        });
    };

    const signout = cb => {
        return fakeAuth.signout(() => {
            setUser(null);
            if (cb) {
                cb();
            }
        });
    };

    return {
        user,
        signin,
        signout
    };
}
/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}
export function useAuth() {
    return useContext(authContext);
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children, ...rest }) {
    // 获取token
    let token = getToken();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                token === 'xxxxxx' ? (
                    children
                ) : (
                    <Redirect to='/home' />
                )
            }
        />
    );
}

