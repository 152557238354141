import React from 'react';
import './index.less';

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        // 组件是否挂载了
        this.mounted = false;
    }
    // 组件卸载回调
    componentWillUnmount() {
        // 标记组件未挂载
        this.mounted = false;
    }
    // 组件加载回调
    componentDidMount() {
        // 标记组件挂载了
        this.mounted = true;
    }
    render() {
        return (
            <div className='site-contact-page'>
                <p>联系人：黄先生</p>
                <p>客服QQ：1429028458</p>
            </div>
        )
    }
}

export default IndexPage;