import { Alert, Button } from 'antd';

export function ErrorPage(props) {
    return (
        <Alert
            message={props.title}
            showIcon
            description={props.desc}
            type="error"
            action={
                <Button size="small" danger onClick={props.onBtnClick}>{props.btnFont}</Button>
            }
        />
    )
}