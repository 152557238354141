import localStorage from "localStorage";

// 是否手机设备
export function isMobile() {
    return document.querySelector('body').offsetWidth <= 1080;
}
// 获取本地数据
function getLocalData(key) {
    let data = localStorage.getItem(key);
    if (data && data !== 'null') {
        data = JSON.parse(data);
    }
    return data;
}
// 保存本地数据
function setLocalData(key, value) {
    if (value) {
        value = JSON.stringify(value);
    }
    localStorage.setItem(key, value)
}
// 删除本地数据
function removeLocalData(key) {
    localStorage.removeItem(key)
}
// 获取token
export function getToken() {
    let token = null;
    let storage = getLocalData('pulingmu');
    if (storage) {
        let time = new Date().getTime();
        if (storage) {
            if (time < storage.expire) {
                token = storage.token;
            } else {
                removeLocalData('pulingmu');
            }
        }
    }
    return token;
}
// 保存token
export function setToken(token) {
    if (token) {
        let obj = {
            token: token,
            expire: new Date().getTime() + 1000 * 60 * 30
        };
        setLocalData('pulingmu', obj);
    } else {
        setLocalData('pulingmu', null);
    }
}
