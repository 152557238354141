import React from 'react';
import { withRouter } from "react-router-dom";
import { Layout, Menu, Button, Typography } from 'antd';
import {
    HomeOutlined,
    InfoCircleOutlined,
    CustomerServiceOutlined,
    // BulbOutlined,
    CommentOutlined,
} from '@ant-design/icons';
import Main from './main/main';
import Ours from './ours/ours';
import Bussiness from './business/business';
import Contact from './contact/contact';
import Children from './children/children';
import './index.less';

const { Link } = Typography;
const { Header, Content, Footer, Sider } = Layout;
// 定义菜单子项key
const rootSubmenuKeys = ['sub1', 'sub2', 'sub3'];
/**
 * 展示内容界面
 */
function DisplayContent(props) {
    // 转换格式
    var selKey = Number(props.selectKeys);
    // 判断界面
    if (selKey === 1) { // 首页
        return <Main />;
    } else if (selKey === 2) { // 关于我们 
        return <Ours />;
    } else if (selKey === 3) { // 商务合作
        return <Bussiness />;
    } else if (selKey === 4) { // 客服中心 
        return <Contact />;
    } else if (selKey === 5) { // 家长监护 
        return <Children />;
    } else {
        return <h1>即将开放，敬请期待</h1>
    }
}
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false, // 是否展示菜单
            openKeys: [], // 菜单打开的sub
            selectKeys: ['1'], // 菜单选择的key
        };
    }
    // 点击sider回调
    handleCollapse = collapsed => {
        this.setState({ collapsed });
    };
    // 点击menu回调
    handleClick = e => {
        // 判断点击哪里
        if (e.key === '100') { // 退出
            // 回到登录
            this.props.history.goBack()
        } else {
            // 刷新内容
            this.setState({
                selectKeys: [e.key]
            });
        }
    }
    // 打开子项回调
    handleOpenChange = keys => {
        // 获取最后的key
        const latestOpenKey = keys.find(key => this.state.openKeys.indexOf(key) === -1);
        // 判断是否需要的子项
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            // 刷新菜单
            this.setState({
                openKeys: keys
            });
        } else {
            // 刷新菜单
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : []
            });
        }
    }

    render() {
        const { openKeys } = this.state;
        const { selectKeys } = this.state;

        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Sider width={150} onCollapse={this.handleCollapse}>
                    <Menu defaultSelectedKeys={['1']} mode="inline" onClick={this.handleClick} onOpenChange={this.handleOpenChange} openKeys={openKeys}>
                        <Menu.Item key="1" icon={<HomeOutlined />}>首页</Menu.Item>
                        <Menu.Item key="2" icon={<InfoCircleOutlined />}>关于我们</Menu.Item>
                        <Menu.Item key="3" icon={<CommentOutlined />}>商务合作</Menu.Item>
                        <Menu.Item key="4" icon={<CustomerServiceOutlined />}>客服中心</Menu.Item>
                        {/* <Menu.Item key="5" icon={<BulbOutlined />}>家长监护</Menu.Item> */}
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Header className="header-layout"  ><font className='title-font'>亿趣官网</font></Header>
                    <Content style={{ backgroundColor: 'white', margin: '2px 0px 2px 0px' }}>
                        <DisplayContent selectKeys={selectKeys} />
                    </Content>
                    <Footer className="footer-layout" >
                        <Button className='btn' target="_blank" type="link" href='https://beian.miit.gov.cn/#/Integrated/'>工业和信息化部</Button><br />
                        广州亿趣网络科技有限公司 联系方式：15626100247<br />
                        <div>地址：广州市天河区官育路10号404房  <Link className='link' href="https://beian.miit.gov.cn/#/Integrated/" target="_blank">
                            粤ICP备18037114号
                        </Link></div>
                    </Footer>
                </Layout>
            </Layout>
        );
        // https://beian.miit.gov.cn/
    }
}

export default withRouter(Home)